<template>
  <div class="menu-item">
        <span v-if="item.items" @click="toggleItem()" class="menu-text" :class="{active: active}" :style="indent">
            {{ item.title }}
            <fa-icon :icon="opened ? 'angle-down' : 'angle-right'" class="float-right shevron"></fa-icon>
        </span>
    <a v-else :href="item.route" class="menu-text" :class="{active: active, empty: item.route === '#'}" :style="indent">{{
        item.title
      }}</a>
    <transition name="slide">
      <div v-if="item.items && opened" class="menu-items">
        <menu-item v-for="(subitem, index) in item.items" :item="subitem" :key="index" :depth="depth + 1"></menu-item>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "menu-item",
  props: {
    item: {},
    depth: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      toggle: null,
    };
  },
  methods: {
    checkItemsHasActive(item) {
      let result = false;
      if (item.active) {
        result = true;
      } else {
        if (item.items) {
          for (let subitem of item.items) {
            if (this.checkItemsHasActive(subitem)) {
              result = true;
              break;
            }
          }
        }
      }
      return result;
    },
    toggleItem() {
      if (this.item.route) {

      } else {
        if (this.toggle === null) {
          this.toggle = !this.active;
        } else {
          this.toggle = !this.toggle;
        }
      }
    },
  },
  computed: {
    indent() {
      return {
        'padding-left': `${16 * this.depth}px`,
      };
    },
    active() {
      return this.checkItemsHasActive(this.item);
    },
    opened() {
      if (this.toggle === null) {
        return this.active;
      } else {
        return this.toggle;
      }
    }
  }
}
</script>

<style scoped>

.active {
  text-decoration: underline;
}

.menu-items > .menu-item:last-child {
  border-bottom: 1px solid #EFF1F3;
  margin-bottom: 16px;
  padding-bottom: 16px;
}

.horizontal-menu-item .menu-items > .menu-item:last-child {
  border-bottom: none;
  margin-bottom: 0px;
  padding-bottom: 0px;
}

.menu-text {
  position: relative;
  display: block;
  padding: 16px 0;
  padding-right: 48px;
  color: #141116;
  user-select: none;
  white-space: nowrap;
}

.heading {

  text-transform: uppercase;
  font-weight: 500;
  color: #141116;
}

.shevron {
  position: absolute;
  top: 20px;
  right: 20px;
}


</style>