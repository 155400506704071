<template>
  <footer v-if="!isGuest" class="mas-footer">
    <div>
      <div class="d-flex flex-column">
        <manager class="only-desktop"/>
        <manager class="only-mobile mobile" :mobile="true"/>
      </div>
    </div>
    <div class="link">
      <span>
        <a href="https://lennufwiki.gitbook.io/wiki/" target="_blank">Инструкция по работе с личным кабинетом</a>
      </span>
    </div>
  </footer>
</template>

<script>


import Manager from '../account-tools/manager.vue'

export default {
  name: 'vue-footer',
  components: {Manager},
  data() {
    return {};
  },

};
</script>
<style scoped>
.mas-footer {
  display: flex;
  background-color: #141116;
  height: 96px;
  padding-bottom: 21px;
  color: #fff;
  font-size: 14px;
  /* line-height: 16px;    */
  align-items: flex-end;
  justify-content: space-between;
}

.mas-footer a, .mas-footer a:hover {
  color: #fff;
}

.link {
  padding-right: 32px;
}

.only-mobile {
  display: none;
}

.only-desktop {
  display: block;
}

@media screen and (max-width: 1023px) {
  .mas-footer {
    display: none;
  }
}
</style>
