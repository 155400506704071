<template>
  <div class="ModalMobile" ref="modal" v-show="display">
    <div class="ModalMobile-header">
      <div class="ModalMobile-close" @click="close">
        <img src="/images/close.svg">
      </div>
      <div class="ModalMobile-title">
        <slot name="header"></slot>
      </div>

    </div>
    <div class="ModalMobile-body">
      <div class="mobile-padding-fix">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isOpen: {
      default: true
    }
  },

  data() {
    return {
      screen: 0,
    }
  },

  computed: {
    display() {
      return this.isOpen || this.screen >= 1024
    }
  },

  watch: {
    isOpen: {
      immediate: true,
      deep: true,
      handler(val) {
        // console.log(document.getElementsByTagName('body')[0])
        if (document.getElementsByTagName('body')[0]) {
          //console.log(this.screen)
          if (val && this.screen) {
            document.getElementsByTagName('body')[0].style = 'height: 100%!important; overflow: hidden; position: fixed; top: 0; left: 0;'
          } else {
            document.getElementsByTagName('body')[0].style = 'height: auto; overflow: auto; position: relative;'
          }
        }
      }
    }
  },


  mounted() {
    if (window) {
      this.resize();
      window.addEventListener('resize', this.resize);
      //document.getElementsByTagName('html').addEventListener('resize', this.resize);
    }
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.resize);
    // document.removeEventListener('resize', this.resize);
  },

  methods:
      {
        close() {
          document.getElementsByTagName('body')[0].style = 'height: auto; overflow-y: auto; position: relative;'
          this.$emit('close')
        },

        resize() {
          this.screen = Math.min(window.screen.width, document.documentElement.clientWidth)
          let screenHeight = Math.min(window.screen.height, document.documentElement.clientHeight)
          // console.log('RESIZE ' + window.screen.width +" : "+ document.documentElement.clientWidth)


          if (this.screen < 1024) {
            this.$refs.modal.style.width = this.screen + 'px';
            this.$refs.modal.style.height = screenHeight + 'px';
          } else {
            document.getElementsByTagName('body')[0].style = 'height: auto; overflow-y: auto; position: relative;'
            this.$refs.modal.style.width = '';
            this.$refs.modal.style.height = '';
          }
        }
      }
}
</script>

<style>

.ModalMobile-header {
  display: none;
}

@media screen and (max-width: 1023px) {
  .mobile-padding-fix {
    /* padding-bottom: 180px */
  }

  .ModalMobile {
    position: fixed;
    top: 0;
    left: 0;
    background-color: #ffffff;
    color: #141116;
    z-index: 999;
    overflow-x: hidden;
    overflow-y: scroll;
  }


  .ModalMobile-header {
    position: fixed;
    top: 0;
    width: 100vw;
    display: flex;
    height: 48px;
    background-color: #000;
    align-items: center;
    z-index: 9999;
  }

  .ModalMobile-close {
    padding: 10px;
    padding-left: 22px;
    padding-right: 15px;
  }

  .ModalMobile-title {
    color: #fff;
    font-size: 16px;
  }

  .ModalMobile-body {
    padding-top: 48px;
  }
}


</style>