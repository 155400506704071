<template>
  <div>
    <a href="/communications/chats">
      <div :class="{button: label}">
        <svg width="24" height="16" viewBox="0 0 24 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd"
                d="M2 0C0.89543 0 0 0.895431 0 2L0 14C0 15.1046 0.895431 16 2 16L22 16C23.1046 16 24 15.1046 24 14L24 2C24 0.89543 23.1046 0 22 0L2 0ZM20.4569 2L3.54314 2L12 9.65145L20.4569 2ZM2 3.30093L2 14L22 14L22 3.30095L13.3418 11.1345C12.58 11.8238 11.42 11.8238 10.6582 11.1345L2 3.30093Z"
                :fill="color"/>
        </svg>
        <div class="label" v-if="label" :style="{color: color}">СООБЩЕНИЯ</div>
      </div>
    </a>
  </div>
</template>

<script>
export default {
  props: {
    label: {default: false},
    colorInverse: {default: false},
  },

  computed: {
    color() {
      return this.colorInverse ? '#141116' : '#FFFFFF'
    }
  }

}
</script>

<style scoped>
.button {
  display: flex;
  align-items: center;
  padding: 16px;
}

.label {
  padding-left: 16px;
  font-weight: 500;
}
</style>