import Vue from 'vue'

const toPhone = number => {
    //+74479968180

    //to 16.11.2019, 12:27
    return `${number[0]}${number[1]} (${number[2]}${number[3]}${number[4]}) ${number[5]}${number[6]}${number[7]}-${number[8]}${number[9]}-${number[10]}${number[11]}`
}

Vue.filter('phone', toPhone)

export default toPhone