var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { class: { button: _vm.label } }, [
      _c(
        "div",
        {
          staticClass: "mas-icon",
          on: {
            click: function($event) {
              _vm.notificationsOpened = !_vm.notificationsOpened
            }
          }
        },
        [
          _vm.notificationsCount
            ? _c(
                "div",
                { staticClass: "mas-icon-num", style: { color: _vm.color } },
                [_vm._v(_vm._s(_vm.notificationsCount))]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "svg",
            {
              attrs: {
                width: "22",
                height: "19",
                viewBox: "0 0 22 19",
                fill: "none",
                xmlns: "http://www.w3.org/2000/svg"
              }
            },
            [
              !_vm.notificationsCount
                ? _c("g", [
                    _c("path", {
                      attrs: {
                        d:
                          "M11 9.5C11.8284 9.5 12.5 8.82843 12.5 8C12.5 7.17157 11.8284 6.5 11 6.5C10.1716 6.5 9.5 7.17157 9.5 8C9.5 8.82843 10.1716 9.5 11 9.5Z",
                        fill: _vm.color
                      }
                    }),
                    _vm._v(" "),
                    _c("path", {
                      attrs: {
                        d:
                          "M17.5 8C17.5 8.82843 16.8284 9.5 16 9.5C15.1716 9.5 14.5 8.82843 14.5 8C14.5 7.17157 15.1716 6.5 16 6.5C16.8284 6.5 17.5 7.17157 17.5 8Z",
                        fill: _vm.color
                      }
                    }),
                    _vm._v(" "),
                    _c("path", {
                      attrs: {
                        d:
                          "M6 9.5C6.82843 9.5 7.5 8.82843 7.5 8C7.5 7.17157 6.82843 6.5 6 6.5C5.17157 6.5 4.5 7.17157 4.5 8C4.5 8.82843 5.17157 9.5 6 9.5Z",
                        fill: _vm.color
                      }
                    })
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  "fill-rule": "evenodd",
                  "clip-rule": "evenodd",
                  d:
                    "M2 0C0.89543 0 0 0.895431 0 2L0 14C0 15.1046 0.89543 16 2 16H3L3 18C3 18.3466 3.17945 18.6684 3.47427 18.8507C3.76909 19.0329 4.13723 19.0494 4.44721 18.8944L10.2361 16L20 16C21.1046 16 22 15.1046 22 14L22 2C22 0.89543 21.1046 0 20 0L2 0ZM2 2L20 2L20 14L10.2361 14C9.92558 14 9.61935 14.0723 9.34164 14.2111L5 16.382V15C5 14.4477 4.55228 14 4 14H2L2 2Z",
                  fill: _vm.color
                }
              })
            ]
          )
        ]
      ),
      _vm._v(" "),
      _vm.label
        ? _c("div", { staticClass: "label", style: { color: _vm.color } }, [
            _vm._v("УВЕДОМЛЕНИЯ")
          ])
        : _vm._e()
    ]),
    _vm._v(" "),
    _vm.notificationsOpened
      ? _c(
          "div",
          { staticClass: "notifications shadow" },
          [
            _c("mobile-modal", {
              on: {
                close: function($event) {
                  _vm.notificationsOpened = false
                }
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "header",
                    fn: function() {
                      return [_vm._v("УВЕДОМЛЕНИЯ")]
                    },
                    proxy: true
                  },
                  {
                    key: "default",
                    fn: function() {
                      return [
                        _c(
                          "button",
                          {
                            staticClass: "notifications-btn btn btn-link p-2",
                            attrs: { type: "button" },
                            on: {
                              click: function($event) {
                                return _vm.markNotifications()
                              }
                            }
                          },
                          [
                            _vm._v(
                              "Пометить всё как\n          прочитанное\n        "
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "notifications-wrapper overflow-auto"
                          },
                          _vm._l(_vm.notifications, function(notification) {
                            return _c(
                              "div",
                              { staticClass: "notifications-item" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "card-body p-1",
                                    class:
                                      notification.status === 2
                                        ? "notification-old"
                                        : ""
                                  },
                                  [
                                    _c(
                                      "h6",
                                      { staticClass: "card-title mb-0" },
                                      [
                                        _vm._v(
                                          _vm._s(notification.payload.title)
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "small",
                                      {
                                        staticClass:
                                          "card-subtitle mb-2 text-muted"
                                      },
                                      [_vm._v(_vm._s(notification.created_at))]
                                    ),
                                    _vm._v(" "),
                                    notification.payload.body
                                      ? _c("p", {
                                          staticClass: "card-text mt-2 mb-1",
                                          domProps: {
                                            innerHTML: _vm._s(
                                              notification.payload.body
                                            )
                                          }
                                        })
                                      : _vm._e()
                                  ]
                                )
                              ]
                            )
                          }),
                          0
                        )
                      ]
                    },
                    proxy: true
                  }
                ],
                null,
                false,
                1369786877
              )
            })
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }